.winter-is-coming {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #252526;
  --header-bg: #252526;

  --box-header-bg: #131212;
  --box-resizer-bg: #252526;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;

  --scrollbar: rgba(121, 121, 121, 0.4);
  --lineNumbers: #219fd5;
  --activeLineNumber: #84daff;

  --bg: #282822;
  --foreground: #a4ceee;
  --comment: #999999;
  --className: #d29ffc;
  --functionName: #f7ecb5;
  --decorator: #f7ecb5;
  --at-decorator: #f7ecb5;
  --string: #d8dee8;
  --keyword: #00bff9;
  --tag: #6dbdfa;
  --attr: #f7ecb5;
  --type: #6dbdfa;
  --constKeyword: #00bff9;
  --consoleKeyword: #6dbdfa;
  --constant: #7fff6e;
  --this: #00bff9;

  --propAccess: #a4ceee;
  --method: #f7ecb5;
  --param: #da71b3;
  --call: #f7ecb5;

  --brackets: #a4ceee;
}
