.vstudio {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #2d2d2d;
  --header-bg: #2d2d2d;

  --box-header-bg: #e8e8e8;
  --box-resizer-bg: #ececec;
  --box-resizer-border-left-color: rgba(0, 0, 0, 0.1);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.18);
  --tab-bg: #f7f7f7;
  --tab-color: #2b2828;
  --editor-action-color: #6c6c6c;
  --editor-action-hovered-color: #222;
  --cursor-color: #000;

  --scrollbar: rgba(100, 100, 100, 0.4);
  --lineNumbers: #2b91af;
  --activeLineNumber: #2b91af;

  --bg: #fff;
  --foreground: #000;
  --comment: #008000;
  --className: #000000;
  --functionName: #000000;
  --decorator: #000000;
  --at-decorator: #000000;
  --string: #008000;
  --keyword: #0000ff;
  --tag: #800000;
  --attr: #ff0000;
  --type: #000000;
  --constKeyword: #0000ff;
  --consoleKeyword: #000000;
  --constant: #09885a;
  --this: #0000ff;

  --propAccess: #000000;
  --method: #000000;
  --param: #000000;
  --call: #000000;

  --brackets: #000;
}
