:root {
  --color: #c7c7c7;
  --color-hover: #fff;
  --solution-tree-bg: #1d2129;
  --sidebar-bg: #2e3440;
  --header-bg: #2e3440;
  --logo-color: #fff;
  --box-header-bg: #1d2129;
  --box-resizer-bg: #2e3440;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --tray-bg: #2978bb;

  --scrollbar: rgba(67, 76, 94, 0.6);
  --lineNumbers: rgba(216, 222, 233, 0.4);
  --activeLineNumber: rgba(216, 222, 233, 0.9);

  --bg: #2e3440;
  --foreground: #d8dee9;
  --comment: #67728a;
  --className: #8fbcbb;
  --functionName: #88c0d0;
  --decorator: #d08770;
  --string: #a3be8c;
  --keyword: #81a1c1;
  --tag: #81a1c1;
  --attr: #8fbcbb;
  --type: #8fbcbb;
  --constKeyword: #81a1c1;
  --consoleKeyword: #8fbcbb;
  --constant: #b48ead;
  --this: #81a1c1;

  --propAccess: #d8dee9;
  --method: #88c0d0;
  --param: #d8dee9;
  --call: #88c0d0;

  --brackets: #d8dee;
}

$media-small: 'max-width: 960px';
$media-x-small: 'max-width: 500px';
