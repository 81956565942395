html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3 {
  margin: 0;
}

img {
  max-width: 100%;
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  font: inherit;
  color: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

:root {
  --color: #c7c7c7;
  --color-hover: #fff;
  --solution-tree-bg: #1d2129;
  --sidebar-bg: #2e3440;
  --header-bg: #2e3440;
  --logo-color: #fff;
  --box-header-bg: #1d2129;
  --box-resizer-bg: #2e3440;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --tray-bg: #2978bb;
  --scrollbar: rgba(67, 76, 94, 0.6);
  --lineNumbers: rgba(216, 222, 233, 0.4);
  --activeLineNumber: rgba(216, 222, 233, 0.9);
  --bg: #2e3440;
  --foreground: #d8dee9;
  --comment: #67728a;
  --className: #8fbcbb;
  --functionName: #88c0d0;
  --decorator: #d08770;
  --string: #a3be8c;
  --keyword: #81a1c1;
  --tag: #81a1c1;
  --attr: #8fbcbb;
  --type: #8fbcbb;
  --constKeyword: #81a1c1;
  --consoleKeyword: #8fbcbb;
  --constant: #b48ead;
  --this: #81a1c1;
  --propAccess: #d8dee9;
  --method: #88c0d0;
  --param: #d8dee9;
  --call: #88c0d0;
  --brackets: #d8dee;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-none {
  display: none !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-y-center {
  display: flex;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.no-wrap {
  flex-wrap: nowrap;
}

.mt-1 {
  margin-top: 5px;
}

.ml-1 {
  margin-left: 5px;
}

.mr-1 {
  margin-right: 5px;
}

.mb-1 {
  margin-bottom: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.mb-2 {
  margin-bottom: 10px;
}

.px-2 {
  padding-right: 10px;
  padding-left: 10px;
}

.p-2 {
  padding: 10px;
}

.ml-3 {
  margin-left: 20px;
}

.ml-auto {
  margin-left: auto;
}

body {
  background: var(--bg);
  color: var(--color);
  overflow: hidden;
}

.trigger {
  cursor: pointer;
}
.trigger:hover {
  color: var(--color-hover);
}
.trigger:hover svg {
  fill: var(--color-hover);
}

h1 {
  color: var(--color-hover);
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
}

ng-run-playground-page ::-webkit-scrollbar {
  width: 14px;
}
ng-run-playground-page ::-webkit-scrollbar-track {
  background: transparent;
}
ng-run-playground-page ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 47%, 0.4);
}

.main .loader {
  --width: 24px;
  --height: 24px;
}

.fake-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.25);
}

.myInlineDecoration {
  text-decoration: underline;
}

.click {
  position: absolute;
  z-index: 4;
  width: 10px;
  height: 10px;
  border-style: solid;
  border-radius: 50%;
  animation: click 200ms ease-out;
  pointer-events: none;
}

@keyframes click {
  0% {
    margin: -0.25em;
    width: 0.5em;
    height: 0.5em;
    border-width: 0.5em;
    opacity: 1;
  }
  100% {
    margin: -1.5em;
    width: 3em;
    height: 3em;
    border-width: 0.03em;
    opacity: 0.2;
  }
}
[data-state=playing] .monaco-editor .cursors-layer > .cursor {
  visibility: visible !important;
  overflow: visible;
}
[data-state=playing] .monaco-editor .cursors-layer > .cursor::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  z-index: 1;
  border: 5px solid transparent;
  border-top-color: #fff;
  border-right-width: 3px;
  border-left-width: 3px;
  transform: translate(-50%, 50%);
}

[data-state=R] .solution-actions,
[data-state=R] .icon-update-link,
[data-state=R] .install-dep,
[data-state=R] .external-deps,
[data-state=R] .icon-settings-btn,
[data-state=R-P] .solution-actions,
[data-state=R-P] .icon-update-link,
[data-state=R-P] .install-dep,
[data-state=R-P] .external-deps,
[data-state=R-P] .icon-settings-btn {
  display: none !important;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.24);
  z-index: 1;
  cursor: pointer;
}
.backdrop i {
  position: absolute;
  width: 60px;
  height: 60px;
  z-index: 2;
  border-radius: 50%;
  border: 4px solid #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgb(29, 161, 242);
}
.backdrop i:before {
  content: "";
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 24px;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 12px 0 12px 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 3px;
}

ng-run-player .backdrop {
  position: fixed;
}

.editor-body {
  /*  [data-mode-id='html'] {
    .monaco-editor .monaco-editor-hover {
      background: #ffc107 !important;
      padding: 10px 15px;
      border: 1px solid #e4ac05 !important;
      border-radius: 4px;

      color: #333 !important;
      text-align: left;
      font-size: 14px;
      line-height: 1.5;

      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);

      .status-bar {
        display: none;
      }
    }

    .monaco-editor-hover-content .mtk1 {
      color: #333 !important;
    }
    .monaco-editor .monaco-editor-hover:hover,
    .monaco-editor .monaco-editor-hover:focus {
      border: 1px solid #e4ac05 !important;
      outline: none;
    }
  }*/
}
.editor-body .monaco-editor .decorationsOverviewRuler,
.editor-body .dragging .monaco-scrollable-element .scrollbar,
.editor-body .dragging .decorationsOverviewRuler,
.editor-body .monaco-aria-container {
  display: none;
}
.editor-body .monaco-editor .rename-box {
  top: 0;
}
.editor-body .source-map-line:before,
.editor-body .errored-line:before {
  content: "";
  top: 2px;
  position: absolute;
  left: -33px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 90"><path d="M0,90 50,0 100,90" fill="%23FFC107"></path><line x1="50" y1="32" x2="50" y2="60" stroke="%23212121" stroke-width="9"></line><circle r="5" cx="50" cy="75" fill="%23212121" /></svg>') no-repeat;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.editor-body .source-map-line:before {
  left: 5px;
  background: #fff;
  border-radius: 50%;
  animation-name: bounceIn;
  animation-duration: 450ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  z-index: 1;
}
.editor-body .monaco-editor,
.editor-body .monaco-editor-background,
.editor-body .monaco-editor .inputarea.ime-input,
.editor-body .monaco-editor .margin,
.editor-body .input-container {
  background: var(--bg);
}
.editor-body .monaco-editor .line-numbers {
  color: var(--lineNumbers);
}
.editor-body .monaco-editor .current-line ~ .line-numbers {
  color: var(--activeLineNumber);
}
.editor-body .monaco-scrollable-element > .scrollbar > .slider {
  background: var(--scrollbar);
}
.editor-body .mtk1,
.editor-body .mtk10,
.editor-body .mtk1.Identifier.NewExpression ~ .mtk1.Identifier.NewExpression {
  color: var(--foreground);
}
.editor-body .TypeReference.FirstBinaryOperator,
.editor-body .GreaterThanToken.TypeReference {
  color: var(--foreground) !important;
}
.editor-body .Identifier.Decorator {
  color: var(--decorator) !important;
}
.editor-body .AtToken.Decorator {
  color: var(--at-decorator) !important;
}
.editor-body .DebuggerKeyword,
.editor-body .FirstLiteralToken,
.editor-body .NullKeyword,
.editor-body .UndefinedKeyword,
.editor-body .FalseKeyword,
.editor-body .TrueKeyword {
  color: var(--constant) !important;
}
.editor-body .Identifier.Method {
  color: var(--method);
}
.editor-body .mtk1.Identifier.ClassDeclaration.PropertyDeclaration.Decorator,
.editor-body .MethodSignature,
.editor-body .mtk1.Identifier.ClassDeclaration.PropertyDeclaration.TypeReference,
.editor-body .Identifier.FunctionDeclaration.TypeReference.Parameter,
.editor-body .Identifier.TypeReference.MethodDeclaration.Parameter,
.editor-body .TypeReference.Constructor,
.editor-body .ModuleDeclaration.Identifier,
.editor-body .InterfaceDeclaration,
.editor-body .ClassKeyword + .mtk1 + .Identifier,
.editor-body .HeritageClause,
.editor-body .SuperKeyword,
.editor-body .mtk1.Identifier.ClassDeclaration.PropertyDeclaration.NewExpression,
.editor-body .Identifier.NewExpression,
.editor-body .Identifier.EnumDeclaration {
  color: var(--className) !important;
}
.editor-body .mtk4:not(.styled-warning) {
  color: var(--attr) !important;
}
.editor-body .Identifier.FunctionDeclaration {
  color: var(--functionName) !important;
}
.editor-body .TypeReference.Parameter,
.editor-body .TypeReference,
.editor-body .StringKeyword.Parameter {
  color: var(--type) !important;
}
.editor-body .ThisKeyword {
  color: var(--this);
}
.editor-body .mtk5,
.editor-body .StringLiteral,
.editor-body .mtk1.StringLiteral:not(.styled-warning),
.editor-body .mtk1.FirstTemplateToken:not(.styled-warning),
.editor-body .RegularExpressionLiteral,
.editor-body .FirstTemplateToken,
.editor-body .TemplateExpression:not(.Identifier) {
  color: var(--string);
}
.editor-body .mtk8 {
  color: var(--tag);
}
.editor-body .ModuleKeyword,
.editor-body .ClassKeyword,
.editor-body .NumberKeyword,
.editor-body .BooleanKeyword,
.editor-body .AnyKeyword,
.editor-body .ConstKeyword,
.editor-body .LetKeyword,
.editor-body .VarKeyword,
.editor-body .ConstructorKeyword,
.editor-body .AnyKeyword,
.editor-body .EnumKeyword,
.editor-body .FunctionKeyword,
.editor-body .StringKeyword,
.editor-body .VoidKeyword,
.editor-body .InterfaceKeyword,
.editor-body .EqualsGreaterThanToken.ArrowFunction,
.editor-body .JSDocParameterTag,
.editor-body .GetKeyword,
.editor-body .SetKeyword {
  color: var(--constKeyword) !important;
}
.editor-body .ConsoleKeyword {
  color: var(--consoleKeyword) !important;
}
.editor-body .mtk7 {
  color: var(--comment);
}
.editor-body .mtk11 {
  color: var(--html-brackets);
}
.editor-body .ImportKeyword,
.editor-body .ExportKeyword,
.editor-body .FirstFutureReservedWord,
.editor-body .ExtendsKeyword,
.editor-body .FromKeyword,
.editor-body .DeleteKeyword,
.editor-body .AsKeyword,
.editor-body .AwaitKeyword,
.editor-body .CatchKeyword,
.editor-body .DefaultKeyword,
.editor-body .ElseKeyword,
.editor-body .IfKeyword,
.editor-body .ReturnKeyword,
.editor-body .TryKeyword,
.editor-body .StaticKeyword,
.editor-body .PrivateKeyword,
.editor-body .PublicKeyword,
.editor-body .ProtectedKeyword,
.editor-body .ReadonlyKeyword,
.editor-body .ContinueKeyword,
.editor-body .DeclareKeyword,
.editor-body .NewKeyword,
.editor-body .LastToken.ForOfStatement,
.editor-body .ForKeyword,
.editor-body .SwitchKeyword,
.editor-body .CaseKeyword,
.editor-body .BreakStatement,
.editor-body .MinusToken,
.editor-body .MinusMinusToken,
.editor-body .PlusToken,
.editor-body .PlusPlusToken,
.editor-body .MinusMinusToken,
.editor-body .FirstCompoundAssignment,
.editor-body .MinusEqualsToken,
.editor-body .AmpersandAmpersandToken,
.editor-body .BarBarToken,
.editor-body .FirstAssignment,
.editor-body .EqualsEqualsToken,
.editor-body .EqualsEqualsEqualsToken,
.editor-body .ExclamationEqualsEqualsToken,
.editor-body .FirstBinaryOperator,
.editor-body .GreaterThanToken,
.editor-body .GreaterThanEqualsToken,
.editor-body .SlashToken,
.editor-body .LessThanEqualsToken,
.editor-body .AsteriskToken,
.editor-body .QuestionToken,
.editor-body .TypeOfKeyword {
  color: var(--keyword) !important;
}
.editor-body .AtToken,
.editor-body .FirstPunctuation,
.editor-body .CloseBraceToken,
.editor-body .CommaToken,
.editor-body .OpenParenToken,
.editor-body .CloseParenToken,
.editor-body .ColonToken,
.editor-body .OpenBracketToken,
.editor-body .CloseBracketToken,
.editor-body .SemicolonToken,
.editor-body .DotToken {
  color: var(--brackets);
}
.editor-body .Identifier.Parameter {
  color: var(--param);
}
.editor-body .CallExpression {
  color: var(--call);
}

.monaco-editor .errored-line:before {
  left: 0;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.45);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
.monaco-scrollable-element,
.lines-content {
  overflow: visible !important;
  contain: none !important;
}

/*
.live-enabled .overflow-guard {
  padding-top: 6px;

  > .margin {
    margin-top: 6px;
  }
}
*/
.atom {
  --color: #c7c7c7;
  --color-hover: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #21252b;
  --header-bg: #21252b;
  --box-header-bg: #131212;
  --box-resizer-bg: #21252b;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --scrollbar: rgba(78, 86, 102, 0.5);
  --lineNumbers: #636d83;
  --activeLineNumber: #9aa8c7;
  --bg: #282c34;
  --foreground: #abb2bf;
  --comment: #5c6370;
  --className: #e5c07b;
  --functionName: #61afef;
  --decorator: #61afef;
  --at-decorator: #61afef;
  --string: #98c379;
  --keyword: #c678dd;
  --tag: #e06c75;
  --attr: #d19a66;
  --type: #56b6c2;
  --constKeyword: #c678dd;
  --consoleKeyword: #e5c07b;
  --constant: #d19a66;
  --this: #e06c75;
  --propAccess: #e06c75;
  --method: #61afef;
  --param: #abb2bf;
  --call: #61afef;
  --brackets: #abb2bf;
}

.dark {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #2d2d2d;
  --header-bg: #2d2d2d;
  --box-header-bg: #131212;
  --box-resizer-bg: #363638;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --scrollbar: rgba(121, 121, 121, 0.4);
  --lineNumbers: #5a5a5a;
  --activeLineNumber: #8a8888;
  --bg: #1e1e1e;
  --foreground: #9cdcfe;
  --comment: #608b4e;
  --className: #4ec9b0;
  --functionName: #dcdcaa;
  --decorator: #dcdcaa;
  --at-decorator: #d4d4d4;
  --string: #ce9178;
  --keyword: #c586c0;
  --tag: #569cd6;
  --attr: #9cdcfe;
  --type: #4ec9b0;
  --constKeyword: #569cd6;
  --consoleKeyword: #4ec9b0;
  --constant: #b5cea8;
  --this: #569cd6;
  --propAccess: #9cdcfe;
  --method: #dcdcaa;
  --param: #9cdcfe;
  --call: #dcdcaa;
  --brackets: #d4d4d4;
}

.dracula {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #15161d;
  --sidebar-bg: #313442;
  --header-bg: #343746;
  --logo-color: #fff;
  --box-header-bg: #15161d;
  --box-resizer-bg: #323948;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #42485a;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --scrollbar: rgba(121, 121, 121, 0.4);
  --lineNumbers: #6272a4;
  --activeLineNumber: #8ba0e0;
  --bg: #282a36;
  --foreground: #f8f8f2;
  --comment: #6272a4;
  --className: #8be9fd;
  --functionName: #50fa7b;
  --decorator: #50fa7b;
  --at-decorator: #50fa7b;
  --string: #f1fa8c;
  --keyword: #ff79c6;
  --tag: #ff79c6;
  --attr: #50fa7b;
  --type: #8be9fd;
  --constKeyword: #ff79c6;
  --consoleKeyword: #8be9fd;
  --constant: #bd93f9;
  --this: #bd93f9;
  --propAccess: #f8f8f2;
  --method: #50fa7b;
  --param: #ffb86c;
  --call: #50fa7b;
  --brackets: #f8f8f2;
}

.light {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #2d2d2d;
  --header-bg: #2d2d2d;
  --box-header-bg: #e8e8e8;
  --box-resizer-bg: #ececec;
  --box-resizer-border-left-color: rgba(0, 0, 0, 0.1);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.18);
  --tab-bg: #f7f7f7;
  --tab-color: #2b2828;
  --editor-action-color: #6c6c6c;
  --editor-action-hovered-color: #222;
  --cursor-color: #000;
  --scrollbar: rgba(100, 100, 100, 0.4);
  --lineNumbers: #2b91af;
  --activeLineNumber: #2b91af;
  --bg: #fff;
  --foreground: #001080;
  --comment: #008000;
  --className: #267f99;
  --functionName: #795e26;
  --decorator: #795e26;
  --at-decorator: #795e26;
  --string: #a31515;
  --keyword: #af00db;
  --tag: #800000;
  --attr: #ff0000;
  --type: #267f99;
  --constKeyword: #0000ff;
  --consoleKeyword: #267f99;
  --constant: #09885a;
  --this: #0000ff;
  --propAccess: #001080;
  --method: #795e26;
  --param: #001080;
  --call: #795e26;
  --brackets: #001080;
}

.material {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #161d21;
  --sidebar-bg: #263238;
  --header-bg: #263238;
  --box-header-bg: #131212;
  --box-resizer-bg: #2b3940;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #384850;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --scrollbar: rgba(0, 0, 0, 0.31);
  --lineNumbers: #3e5d5d;
  --activeLineNumber: #7fa7b9;
  --bg: #1a2327;
  --foreground: #eeffff;
  --comment: #546e7a;
  --className: #ffcb6b;
  --functionName: #82aaff;
  --decorator: #82aaff;
  --at-decorator: #82aaff;
  --string: #c3e88d;
  --keyword: #89ddff;
  --tag: #f07178;
  --attr: #ffcb6b;
  --type: #c792ea;
  --constKeyword: #c792ea;
  --consoleKeyword: #ffcb6b;
  --constant: #f78c6c;
  --this: #ff5370;
  --propAccess: #eeffff;
  --method: #ffcb6b;
  --param: #f78c6c;
  --call: #82aaff;
  --brackets: #eeffff;
}

.monokai {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #3b3b3c;
  --header-bg: #343435;
  --box-header-bg: #131212;
  --box-resizer-bg: #363638;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --scrollbar: rgba(121, 121, 121, 0.4);
  --lineNumbers: #90908a;
  --activeLineNumber: #c2c2bf;
  --bg: #272822;
  --foreground: #f8f8f2;
  --comment: #75715e;
  --className: #a6e22e;
  --functionName: #a6e22e;
  --decorator: #a6e22e;
  --at-decorator: #a6e22e;
  --string: #e6db74;
  --keyword: #f92672;
  --tag: #f92672;
  --attr: #a6e22e;
  --type: #66d9ef;
  --constKeyword: #66d9ef;
  --consoleKeyword: #66d9ef;
  --constant: #ae81ff;
  --this: #fd971f;
  --propAccess: #f8f8f2;
  --method: #a6e22e;
  --param: #fd971f;
  --call: #a6e22e;
  --brackets: #f8f8f2;
}

.monokaiPro {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #151111;
  --sidebar-bg: #2c2525;
  --header-bg: #2c2525;
  --box-header-bg: #151111;
  --box-resizer-bg: #2c2525;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --scrollbar: rgba(255, 241, 243, 0.07);
  --lineNumbers: #5b5353;
  --activeLineNumber: #c3b7b8;
  --bg: #2c2525;
  --foreground: #fff1f3;
  --comment: #72696a;
  --className: #adda78;
  --functionName: #adda78;
  --decorator: #adda78;
  --at-decorator: #adda78;
  --string: #f9cc6c;
  --keyword: #fd6883;
  --tag: #fd6883;
  --attr: #85dacc;
  --type: #85dacc;
  --constKeyword: #85dacc;
  --consoleKeyword: #85dacc;
  --constant: #a8a9eb;
  --this: #c3b7b8;
  --propAccess: #fff1f3;
  --method: #adda78;
  --param: #f38d70;
  --call: #adda78;
  --brackets: #fff1f3;
}

.night-owl {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #000d17;
  --sidebar-bg: #061526;
  --header-bg: #061526;
  --box-header-bg: #000d17;
  --box-resizer-bg: #04111f;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --scrollbar: rgba(8, 77, 129, 0.4);
  --lineNumbers: #4b6479;
  --activeLineNumber: #c5e4fd;
  --bg: #011627;
  --foreground: #d6deeb;
  --comment: #637777;
  --className: #addb67;
  --functionName: #82aaff;
  --decorator: #82aaff;
  --at-decorator: #82aaff;
  --string: #ecc48d;
  --keyword: #c792ea;
  --tag: #7fdbca;
  --attr: #addb67;
  --type: #addb67;
  --constKeyword: #c792ea;
  --consoleKeyword: #addb67;
  --constant: #ff5874;
  --this: #7fdbca;
  --propAccess: #82aaff;
  --method: #82aaff;
  --param: #d6deeb;
  --call: #82aaff;
  --brackets: #d6deeb;
}

.nord {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1d2129;
  --sidebar-bg: #2e3440;
  --header-bg: #2e3440;
  --box-header-bg: #1d2129;
  --box-resizer-bg: #2e3440;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --scrollbar: rgba(67, 76, 94, 0.6);
  --lineNumbers: rgba(216, 222, 233, 0.4);
  --activeLineNumber: rgba(216, 222, 233, 0.9);
  --bg: #2e3440;
  --foreground: #d8dee9;
  --comment: #67728a;
  --className: #8fbcbb;
  --functionName: #88c0d0;
  --decorator: #d08770;
  --at-decorator: #d08770;
  --string: #a3be8c;
  --keyword: #81a1c1;
  --tag: #81a1c1;
  --attr: #8fbcbb;
  --type: #8fbcbb;
  --constKeyword: #81a1c1;
  --consoleKeyword: #8fbcbb;
  --constant: #b48ead;
  --this: #81a1c1;
  --propAccess: #d8dee9;
  --method: #88c0d0;
  --param: #d8dee9;
  --call: #88c0d0;
  --brackets: #d8dee9;
}

.northemDark {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #2d2d2d;
  --header-bg: #2d2d2d;
  --box-header-bg: #131212;
  --box-resizer-bg: #2d2d2d;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --scrollbar: rgba(121, 121, 121, 0.4);
  --lineNumbers: #5a5a5a;
  --activeLineNumber: #ababab;
  --bg: #1a1a1a;
  --foreground: #cbc9c9;
  --comment: #65656f;
  --className: #8fcec8;
  --functionName: #85c1d3;
  --decorator: #85c1d3;
  --at-decorator: #85c1d3;
  --string: #c9cc78;
  --keyword: #87b2ce;
  --tag: #87b2ce;
  --attr: #8fcec8;
  --type: #8fcec8;
  --constKeyword: #5b81af;
  --consoleKeyword: #8fcec8;
  --constant: #c2a1ca;
  --this: #87b2ce;
  --propAccess: #cbc9c9;
  --method: #85c1d3;
  --param: #cbc9c9;
  --call: #85c1d3;
  --brackets: #cbc9c9;
}

.oceanicNext {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #17252d;
  --header-bg: #17252d;
  --box-header-bg: #0f181d;
  --box-resizer-bg: #17252d;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --scrollbar: rgba(121, 121, 121, 0.4);
  --lineNumbers: #5a5a5a;
  --activeLineNumber: #929191;
  --bg: #1b2b34;
  --foreground: #cdd3de;
  --comment: #65737e;
  --className: #fac863;
  --functionName: #6699cc;
  --decorator: #6699cc;
  --at-decorator: #6699cc;
  --string: #99c794;
  --keyword: #c594c5;
  --tag: #eb606b;
  --attr: #bb80b3;
  --type: #fac863;
  --constKeyword: #c594c5;
  --consoleKeyword: #fac863;
  --constant: #f99157;
  --this: #ec5f67;
  --propAccess: #cdd3de;
  --method: #6699cc;
  --param: #f99157;
  --call: #6699cc;
  --brackets: #cdd3de;
}

.vstudio {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #2d2d2d;
  --header-bg: #2d2d2d;
  --box-header-bg: #e8e8e8;
  --box-resizer-bg: #ececec;
  --box-resizer-border-left-color: rgba(0, 0, 0, 0.1);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.18);
  --tab-bg: #f7f7f7;
  --tab-color: #2b2828;
  --editor-action-color: #6c6c6c;
  --editor-action-hovered-color: #222;
  --cursor-color: #000;
  --scrollbar: rgba(100, 100, 100, 0.4);
  --lineNumbers: #2b91af;
  --activeLineNumber: #2b91af;
  --bg: #fff;
  --foreground: #000;
  --comment: #008000;
  --className: #000000;
  --functionName: #000000;
  --decorator: #000000;
  --at-decorator: #000000;
  --string: #008000;
  --keyword: #0000ff;
  --tag: #800000;
  --attr: #ff0000;
  --type: #000000;
  --constKeyword: #0000ff;
  --consoleKeyword: #000000;
  --constant: #09885a;
  --this: #0000ff;
  --propAccess: #000000;
  --method: #000000;
  --param: #000000;
  --call: #000000;
  --brackets: #000;
}

.winter-is-coming {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #252526;
  --header-bg: #252526;
  --box-header-bg: #131212;
  --box-resizer-bg: #252526;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;
  --scrollbar: rgba(121, 121, 121, 0.4);
  --lineNumbers: #219fd5;
  --activeLineNumber: #84daff;
  --bg: #282822;
  --foreground: #a4ceee;
  --comment: #999999;
  --className: #d29ffc;
  --functionName: #f7ecb5;
  --decorator: #f7ecb5;
  --at-decorator: #f7ecb5;
  --string: #d8dee8;
  --keyword: #00bff9;
  --tag: #6dbdfa;
  --attr: #f7ecb5;
  --type: #6dbdfa;
  --constKeyword: #00bff9;
  --consoleKeyword: #6dbdfa;
  --constant: #7fff6e;
  --this: #00bff9;
  --propAccess: #a4ceee;
  --method: #f7ecb5;
  --param: #da71b3;
  --call: #f7ecb5;
  --brackets: #a4ceee;
}