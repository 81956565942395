.dark {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #2d2d2d;
  --header-bg: #2d2d2d;

  --box-header-bg: #131212;
  --box-resizer-bg: #363638;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;

  --scrollbar: rgba(121, 121, 121, 0.4);
  --lineNumbers: #5a5a5a;
  --activeLineNumber: #8a8888;

  --bg: #1e1e1e;
  --foreground: #9cdcfe;
  --comment: #608b4e;
  --className: #4ec9b0;
  --functionName: #dcdcaa;
  --decorator: #dcdcaa;
  --at-decorator: #d4d4d4;
  --string: #ce9178;
  --keyword: #c586c0;
  --tag: #569cd6;
  --attr: #9cdcfe;
  --type: #4ec9b0;
  --constKeyword: #569cd6;
  --consoleKeyword: #4ec9b0;
  --constant: #b5cea8;
  --this: #569cd6;

  --propAccess: #9cdcfe;
  --method: #dcdcaa;
  --param: #9cdcfe;
  --call: #dcdcaa;

  --brackets: #d4d4d4;
}
