.northemDark {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #2d2d2d;
  --header-bg: #2d2d2d;

  --box-header-bg: #131212;
  --box-resizer-bg: #2d2d2d;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;

  --scrollbar: rgba(121, 121, 121, 0.4);
  --lineNumbers: #5a5a5a;
  --activeLineNumber: #ababab;

  --bg: #1a1a1a;
  --foreground: #cbc9c9;
  --comment: #65656f;
  --className: #8fcec8;
  --functionName: #85c1d3;
  --decorator: #85c1d3;
  --at-decorator: #85c1d3;
  --string: #c9cc78;
  --keyword: #87b2ce;
  --tag: #87b2ce;
  --attr: #8fcec8;
  --type: #8fcec8;
  --constKeyword: #5b81af;
  --consoleKeyword: #8fcec8;
  --constant: #c2a1ca;
  --this: #87b2ce;

  --propAccess: #cbc9c9;
  --method: #85c1d3;
  --param: #cbc9c9;
  --call: #85c1d3;

  --brackets: #cbc9c9;
}
