$spacer: 10px;

$spacer-0: 0 !default;
$spacer-1: round($spacer / 2) !default;
$spacer-2: $spacer !default;
$spacer-3: $spacer * 2 !default;
$spacer-4: $spacer * 3 !default;
$spacer-5: $spacer * 4 !default;
$spacer-6: $spacer * 5 !default;

.mt-1 {
  margin-top: $spacer-1;
}

.ml-1 {
  margin-left: $spacer-1;
}

.mr-1 {
  margin-right: $spacer-1;
}

.mb-1 {
  margin-bottom: $spacer-1;
}

.ml-2 {
  margin-left: $spacer-2;
}

.mb-2 {
  margin-bottom: $spacer-2;
}

.px-2 {
  padding-right: $spacer-2;
  padding-left: $spacer-2;
}

.p-2 {
  padding: $spacer-2;
}

.ml-3 {
  margin-left: $spacer-3;
}

.ml-auto {
  margin-left: auto;
}
