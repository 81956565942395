.monokaiPro {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #151111;
  --sidebar-bg: #2c2525;
  --header-bg: #2c2525;

  --box-header-bg: #151111;
  --box-resizer-bg: #2c2525;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;

  --scrollbar: rgba(255, 241, 243, 0.07);
  --lineNumbers: #5b5353;
  --activeLineNumber: #c3b7b8;

  --bg: #2c2525;
  --foreground: #fff1f3;
  --comment: #72696a;
  --className: #adda78;
  --functionName: #adda78;
  --decorator: #adda78;
  --at-decorator: #adda78;
  --string: #f9cc6c;
  --keyword: #fd6883;
  --tag: #fd6883;
  --attr: #85dacc;
  --type: #85dacc;
  --constKeyword: #85dacc;
  --consoleKeyword: #85dacc;
  --constant: #a8a9eb;
  --this: #c3b7b8;

  --propAccess: #fff1f3;
  --method: #adda78;
  --param: #f38d70;
  --call: #adda78;

  --brackets: #fff1f3;
}
