.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-none {
  display: none !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-y-center {
  display: flex;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.no-wrap {
  flex-wrap: nowrap;
}
