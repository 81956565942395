.dracula {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #15161d;
  --sidebar-bg: #313442;
  --header-bg: #343746;
  --logo-color: #fff;
  --box-header-bg: #15161d;
  --box-resizer-bg: #323948;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #42485a;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;

  --scrollbar: rgba(121, 121, 121, 0.4);
  --lineNumbers: #6272a4;
  --activeLineNumber: #8ba0e0;

  --bg: #282a36;
  --foreground: #f8f8f2;
  --comment: #6272a4;
  --className: #8be9fd;
  --functionName: #50fa7b;
  --decorator: #50fa7b;
  --at-decorator: #50fa7b;
  --string: #f1fa8c;
  --keyword: #ff79c6;
  --tag: #ff79c6;
  --attr: #50fa7b;
  --type: #8be9fd;
  --constKeyword: #ff79c6;
  --consoleKeyword: #8be9fd;
  --constant: #bd93f9;
  --this: #bd93f9;

  --propAccess: #f8f8f2;
  --method: #50fa7b;
  --param: #ffb86c;
  --call: #50fa7b;

  --brackets: #f8f8f2;
}
