html {
  box-sizing: border-box;
}
// https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3 {
  margin: 0;
}

img {
  max-width: 100%;
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  font: inherit;
  color: inherit;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
