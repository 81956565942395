.atom {
  --color: #c7c7c7;
  --color-hover: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #21252b;
  --header-bg: #21252b;

  --box-header-bg: #131212;
  --box-resizer-bg: #21252b;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;

  --scrollbar: rgba(78, 86, 102, 0.5);
  --lineNumbers: #636d83;
  --activeLineNumber: #9aa8c7;

  --bg: #282c34;
  --foreground: #abb2bf;
  --comment: #5c6370;
  --className: #e5c07b;
  --functionName: #61afef;
  --decorator: #61afef;
  --at-decorator: #61afef;
  --string: #98c379;
  --keyword: #c678dd;
  --tag: #e06c75;
  --attr: #d19a66;
  --type: #56b6c2;
  --constKeyword: #c678dd;
  --consoleKeyword: #e5c07b;
  --constant: #d19a66;
  --this: #e06c75;

  --propAccess: #e06c75;
  --method: #61afef;
  --param: #abb2bf;
  --call: #61afef;

  --brackets: #abb2bf;
}
