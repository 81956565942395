.material {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #161d21;
  --sidebar-bg: #263238;
  --header-bg: #263238;

  --box-header-bg: #131212;
  --box-resizer-bg: #2b3940;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #384850;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;

  --scrollbar: rgba(0, 0, 0, 0.31);
  --lineNumbers: #3e5d5d;
  --activeLineNumber: #7fa7b9;

  --bg: #1a2327;
  --foreground: #eeffff;
  --comment: #546e7a;
  --className: #ffcb6b;
  --functionName: #82aaff;
  --decorator: #82aaff;
  --at-decorator: #82aaff;
  --string: #c3e88d;
  --keyword: #89ddff;
  --tag: #f07178;
  --attr: #ffcb6b;
  --type: #c792ea;
  --constKeyword: #c792ea;
  --consoleKeyword: #ffcb6b;
  --constant: #f78c6c;
  --this: #ff5370;
  --propAccess: #eeffff;
  --method: #ffcb6b;
  --param: #f78c6c;
  --call: #82aaff;

  --brackets: #eeffff;
}
