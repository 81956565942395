.monokai {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #3b3b3c;
  --header-bg: #343435;

  --box-header-bg: #131212;
  --box-resizer-bg: #363638;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;

  --scrollbar: rgba(121, 121, 121, 0.4);
  --lineNumbers: #90908a;
  --activeLineNumber: #c2c2bf;

  --bg: #272822;
  --foreground: #f8f8f2;
  --comment: #75715e;
  --className: #a6e22e;
  --functionName: #a6e22e;
  --decorator: #a6e22e;
  --at-decorator: #a6e22e;
  --string: #e6db74;
  --keyword: #f92672;
  --tag: #f92672;
  --attr: #a6e22e;
  --type: #66d9ef;
  --constKeyword: #66d9ef;
  --consoleKeyword: #66d9ef;
  --constant: #ae81ff;
  --this: #fd971f;
  --propAccess: #f8f8f2;
  --method: #a6e22e;
  --param: #fd971f;
  --call: #a6e22e;

  --brackets: #f8f8f2;
}
