.editor-body {
  .monaco-editor .decorationsOverviewRuler,
  .dragging .monaco-scrollable-element .scrollbar,
  .dragging .decorationsOverviewRuler,
  .monaco-aria-container {
    display: none;
  }

  .monaco-editor .rename-box {
    top: 0;
  }

  /*  [data-mode-id='html'] {
    .monaco-editor .monaco-editor-hover {
      background: #ffc107 !important;
      padding: 10px 15px;
      border: 1px solid #e4ac05 !important;
      border-radius: 4px;

      color: #333 !important;
      text-align: left;
      font-size: 14px;
      line-height: 1.5;

      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);

      .status-bar {
        display: none;
      }
    }

    .monaco-editor-hover-content .mtk1 {
      color: #333 !important;
    }
    .monaco-editor .monaco-editor-hover:hover,
    .monaco-editor .monaco-editor-hover:focus {
      border: 1px solid #e4ac05 !important;
      outline: none;
    }
  }*/

  .source-map-line:before,
  .errored-line:before {
    content: '';
    top: 2px;
    position: absolute;
    left: -33px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 90"><path d="M0,90 50,0 100,90" fill="%23FFC107"></path><line x1="50" y1="32" x2="50" y2="60" stroke="%23212121" stroke-width="9"></line><circle r="5" cx="50" cy="75" fill="%23212121" /></svg>')
      no-repeat;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .source-map-line:before {
    left: 5px;
    background: #fff;
    border-radius: 50%;
    animation-name: bounceIn;
    animation-duration: 450ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    z-index: 1;
  }

  .monaco-editor,
  .monaco-editor-background,
  .monaco-editor .inputarea.ime-input,
  .monaco-editor .margin,
  .input-container {
    background: var(--bg);
  }

  .monaco-editor .line-numbers {
    color: var(--lineNumbers);
  }

  .monaco-editor .current-line ~ .line-numbers {
    color: var(--activeLineNumber);
  }

  .monaco-scrollable-element > .scrollbar > .slider {
    background: var(--scrollbar);
  }

  .mtk1,
  .mtk10,
  .mtk1.Identifier.NewExpression ~ .mtk1.Identifier.NewExpression {
    color: var(--foreground);
  }

  .TypeReference.FirstBinaryOperator,
  .GreaterThanToken.TypeReference {
    color: var(--foreground) !important;
  }

  .Identifier.Decorator {
    color: var(--decorator) !important;
  }

  .AtToken.Decorator {
    color: var(--at-decorator) !important;
  }

  .DebuggerKeyword,
  .FirstLiteralToken,
  .NullKeyword,
  .UndefinedKeyword,
  .FalseKeyword,
  .TrueKeyword {
    color: var(--constant) !important;
  }

  .Identifier.Method {
    color: var(--method);
  }

  .mtk1.Identifier.ClassDeclaration.PropertyDeclaration.Decorator,
  .MethodSignature,
  .mtk1.Identifier.ClassDeclaration.PropertyDeclaration.TypeReference,
  .Identifier.FunctionDeclaration.TypeReference.Parameter,
  .Identifier.TypeReference.MethodDeclaration.Parameter,
  .TypeReference.Constructor,
  .ModuleDeclaration.Identifier,
  .InterfaceDeclaration,
  .ClassKeyword + .mtk1 + .Identifier,
  .HeritageClause,
  .SuperKeyword,
  .mtk1.Identifier.ClassDeclaration.PropertyDeclaration.NewExpression,
  .Identifier.NewExpression,
  .Identifier.EnumDeclaration {
    color: var(--className) !important;
  }

  .mtk4:not(.styled-warning) {
    color: var(--attr) !important;
  }

  .Identifier.FunctionDeclaration {
    color: var(--functionName) !important;
  }

  .TypeReference.Parameter,
  .TypeReference,
  .StringKeyword.Parameter {
    color: var(--type) !important;
  }

  .ThisKeyword {
    color: var(--this);
  }

  .mtk5,
  .StringLiteral,
  .mtk1.StringLiteral:not(.styled-warning),
  .mtk1.FirstTemplateToken:not(.styled-warning),
  .RegularExpressionLiteral,
  .FirstTemplateToken,
  .TemplateExpression:not(.Identifier) {
    color: var(--string);
  }

  .mtk8 {
    color: var(--tag);
  }

  .ModuleKeyword,
  .ClassKeyword,
  .NumberKeyword,
  .BooleanKeyword,
  .AnyKeyword,
  .ConstKeyword,
  .LetKeyword,
  .VarKeyword,
  .ConstructorKeyword,
  .AnyKeyword,
  .EnumKeyword,
  .FunctionKeyword,
  .StringKeyword,
  .VoidKeyword,
  .InterfaceKeyword,
  .EqualsGreaterThanToken.ArrowFunction,
  .JSDocParameterTag,
  .GetKeyword,
  .SetKeyword {
    color: var(--constKeyword) !important;
  }

  .ConsoleKeyword {
    color: var(--consoleKeyword) !important;
  }

  .mtk7 {
    color: var(--comment);
  }

  .mtk11 {
    color: var(--html-brackets);
  }

  .ImportKeyword,
  .ExportKeyword,
  .FirstFutureReservedWord,
  .ExtendsKeyword,
  .FromKeyword,
  .DeleteKeyword,
  .AsKeyword,
  .AwaitKeyword,
  .CatchKeyword,
  .DefaultKeyword,
  .ElseKeyword,
  .IfKeyword,
  .ReturnKeyword,
  .TryKeyword,
  .StaticKeyword,
  .PrivateKeyword,
  .PublicKeyword,
  .ProtectedKeyword,
  .ReadonlyKeyword,
  .ContinueKeyword,
  .DeclareKeyword,
  .NewKeyword,
  .LastToken.ForOfStatement,
  .ForKeyword,
  .SwitchKeyword,
  .CaseKeyword,
  .BreakStatement,
  .MinusToken,
  .MinusMinusToken,
  .PlusToken,
  .PlusPlusToken,
  .MinusMinusToken,
  .FirstCompoundAssignment,
  .MinusEqualsToken,
  .AmpersandAmpersandToken,
  .BarBarToken,
  .FirstAssignment,
  .EqualsEqualsToken,
  .EqualsEqualsEqualsToken,
  .ExclamationEqualsEqualsToken,
  .FirstBinaryOperator,
  .GreaterThanToken,
  .GreaterThanEqualsToken,
  .SlashToken,
  .LessThanEqualsToken,
  .AsteriskToken,
  .QuestionToken,
  .TypeOfKeyword {
    color: var(--keyword) !important;
  }

  .AtToken,
  .FirstPunctuation,
  .CloseBraceToken,
  .CommaToken,
  .OpenParenToken,
  .CloseParenToken,
  .ColonToken,
  .OpenBracketToken,
  .CloseBracketToken,
  .SemicolonToken,
  .DotToken {
    color: var(--brackets);
  }

  .Identifier.Parameter {
    color: var(--param);
  }

  .CallExpression {
    color: var(--call);
  }
}

.monaco-editor {
  .errored-line:before {
    left: 0;
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.45);
  }

  80% {
    opacity: 1;
    transform: scale(0.89);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

.monaco-scrollable-element,
.lines-content {
  overflow: visible !important;
  contain: none !important;
}

/*
.live-enabled .overflow-guard {
  padding-top: 6px;

  > .margin {
    margin-top: 6px;
  }
}
*/
