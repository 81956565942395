.oceanicNext {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #1e1f1c;
  --sidebar-bg: #17252d;
  --header-bg: #17252d;

  --box-header-bg: #0f181d;
  --box-resizer-bg: #17252d;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;

  --scrollbar: rgba(121, 121, 121, 0.4);
  --lineNumbers: #5a5a5a;
  --activeLineNumber: #929191;

  --bg: #1b2b34;
  --foreground: #cdd3de;
  --comment: #65737e;
  --className: #fac863;
  --functionName: #6699cc;
  --decorator: #6699cc;
  --at-decorator: #6699cc;
  --string: #99c794;
  --keyword: #c594c5;
  --tag: #eb606b;
  --attr: #bb80b3;
  --type: #fac863;
  --constKeyword: #c594c5;
  --consoleKeyword: #fac863;
  --constant: #f99157;
  --this: #ec5f67;

  --propAccess: #cdd3de;
  --method: #6699cc;
  --param: #f99157;
  --call: #6699cc;

  --brackets: #cdd3de;
}
