.night-owl {
  --color: #c7c7c7;
  --hovered-color: #fff;
  --solution-tree-bg: #000d17;
  --sidebar-bg: #061526;
  --header-bg: #061526;

  --box-header-bg: #000d17;
  --box-resizer-bg: #04111f;
  --box-resizer-border-left-color: rgba(255, 255, 255, 0.15);
  --box-resizer-border-right-color: rgba(0, 0, 0, 0.34);
  --tab-bg: #444446;
  --tab-color: #fff;
  --editor-action-color: #c7c7c7;
  --editor-action-hovered-color: #fff;
  --cursor-color: #fff;

  --scrollbar: rgba(8, 77, 129, 0.4);
  --lineNumbers: #4b6479;
  --activeLineNumber: #c5e4fd;

  --bg: #011627;
  --foreground: #d6deeb;
  --comment: #637777;
  --className: #addb67;
  --functionName: #82aaff;
  --decorator: #82aaff;
  --at-decorator: #82aaff;
  --string: #ecc48d;
  --keyword: #c792ea;
  --tag: #7fdbca;
  --attr: #addb67;
  --type: #addb67;
  --constKeyword: #c792ea;
  --consoleKeyword: #addb67;
  --constant: #ff5874;
  --this: #7fdbca;
  --propAccess: #82aaff;
  --method: #82aaff;
  --param: #d6deeb;
  --call: #82aaff;

  --brackets: #d6deeb;
}
