body {
  background: var(--bg);
  color: var(--color);
  overflow: hidden;
}

.trigger {
  cursor: pointer;

  &:hover {
    color: var(--color-hover);

    svg {
      fill: var(--color-hover);
    }
  }
}

h1 {
  color: var(--color-hover);
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
}

ng-run-playground-page {
  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: hsla(0, 0%, 47%, 0.4);
  }
}

.main {
  .loader {
    --width: 24px;
    --height: 24px;
  }
}

.fake-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.25);
}

.myInlineDecoration {
  text-decoration: underline;
}

.click {
  position: absolute;
  z-index: 4;
  width: 10px;
  height: 10px;
  border-style: solid;
  border-radius: 50%;
  animation: click 200ms ease-out;
  pointer-events: none;
}

@keyframes click {
  0% {
    margin: -0.25em;
    width: 0.5em;
    height: 0.5em;
    border-width: 0.5em;
    opacity: 1;
  }

  100% {
    margin: -1.5em;
    width: 3em;
    height: 3em;
    border-width: 0.03em;
    opacity: 0.2;
  }
}

[data-state='playing'] .monaco-editor .cursors-layer > {
  .cursor {
    visibility: visible !important;
    overflow: visible;
  }

  .cursor::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    z-index: 1;
    border: 5px solid transparent;
    border-top-color: #fff;
    border-right-width: 3px;
    border-left-width: 3px;
    transform: translate(-50%, 50%);
  }
}

[data-state='R'],
[data-state='R-P'] {
  .solution-actions,
  .icon-update-link,
  .install-dep,
  .external-deps,
  .icon-settings-btn {
    display: none !important;
  }
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.24);
  z-index: 1;
  cursor: pointer;

  i {
    position: absolute;
    width: 60px;
    height: 60px;
    z-index: 2;
    border-radius: 50%;
    border: 4px solid #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgb(29, 161, 242);

    &:before {
      content: '';
      background: transparent;
      box-sizing: border-box;
      width: 0;
      height: 24px;
      border-color: transparent transparent transparent #fff;
      border-style: solid;
      border-width: 12px 0 12px 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: 3px;
    }
  }
}

ng-run-player .backdrop {
  position: fixed;
}
